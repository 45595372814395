import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, Subject, catchError, map, takeUntil } from 'rxjs';
import { SetUser } from '../../user/actions/user.actions';
import { CheckTermsActions } from '../actions/check-terms.actions';
import { KeystoneService } from 'src/app/core/services/keystone/keystone.service';
import { Store } from '@ngrx/store';
import { ModalsActions } from '../../modals/actions/modals.actions';
import { appState } from 'src/app';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';
import { TermsAndConditionsService } from 'src/app/core/services/terms-and-conditions/terms-and-conditions.service';
import { CookieService } from 'src/app/core/services/cookies/cookie.service';
import { selectLoyalty } from '../../loyalty/selector/loyalty.selector';

@Injectable()
export class CheckTermsEffects {
  unsubscribe$ = new Subject();
  loyalty$: Observable<any> = this.store.select(selectLoyalty);
  constructor(
    private actions$: Actions,
    private keystone: KeystoneService,
    private store: Store<appState>,
    private storage: LocalStorageService,
    private cookie: CookieService,
    private termsAndCondService: TermsAndConditionsService
  ) {}

  $checkUserExist = createEffect(() =>
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(SetUser['[SetUser]SetUserProfileSuccess']),
      map(() => {
        this.checkTermsData();
        return CheckTermsActions['[CheckTerms]TermsSuccess']();
      })
    )
  );

  checkTermsData() {
    this.keystone.getAllTermsPages().subscribe((response: any) => {
      const latestTermsVersion = response[0].version;
      this.termsAndCondService
        .getStorage()
        .pipe(
          catchError((): any => {
            // old logic start if no storage data
            if (this.storage.getSessionStorage('termsUpdateSkip') === 'true') {
              this.termsAndCondService.postStorage('terms', latestTermsVersion).subscribe();
              this.termsAndCondService.acceptTerms();
              this.storage.removeSessionStorage('termsUpdateSkip');
              return;
            }
            if (response[0] && latestTermsVersion) {
              setTimeout(() => {
                this.store.dispatch(
                  ModalsActions['[modals]OpenModalSuccess']({
                    id: 'terms-acceptance',
                    unClosable: true,
                  })
                );
              }, 1500);
            }
            // old logic end
          })
        )
        .subscribe((res: any) => {
          if (res?.terms !== Number(latestTermsVersion)) {
            setTimeout(() => {
              this.store.dispatch(
                ModalsActions['[modals]OpenModalSuccess']({
                  id: 'terms-acceptance',
                  unClosable: true,
                })
              );
            }, 1500);
          } else {
            setTimeout(() => {
              // this.loyalty$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
              //   if (
              //     res?.isLoaded &&
              //     res?.readyToClaimQuest?.readyToClaimAuthQuest &&
              //     !this.cookie.getCookie('readyToClaimAuthQuest')
              //   ) {
              //     const today = new Date();
              //     today.setUTCHours(23, 59, 0, 0);
              //     document.cookie = `readyToClaimAuthQuest=${true};expires=${today.toUTCString()};path=/;`;
              //     this.store.dispatch(
              //       ModalsActions['[modals]OpenModalSuccess']({
              //         id: 'quest-notification',
              //         state: 'notification',
              //       })
              //     );
              //   }
              // });
            }, 1500);
          }
        });
      this.unsub();
    });
  }

  unsub() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
