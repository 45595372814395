import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { checkUserExist } from '../actions/sign-up.actions';
import { RegistrationService } from 'src/app/core/services/rerister.service.ts/registrations.service';

@Injectable()
export class checkUserExistEffect {
  constructor(
    private actions$: Actions,
    private registrationService: RegistrationService
  ) {}

  $checkUserExist = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkUserExist['[checkUserExist]CheckUserExist']),
      mergeMap((action) =>
        this.registrationService.checkRegisterParams(action).pipe(
          map((res: any) => {
            // forkJoin({
            //   coduluz: this.registrationService.checkRegisterParamsCoduluz(action),
            //   registerParams: this.registrationService.checkRegisterParams(action),
            // }).pipe(
            //   map(({ coduluz, registerParams }) => {
            return checkUserExist['[checkUserExist]CheckUserExistSuccess']({
              data: { userData: action, ...res },
              // data: { userData: action, coduluz, ...registerParams },
            });
          })
        )
      )
    );
  });
}
