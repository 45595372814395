import { Component, Input, OnDestroy } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { selectLoyalty } from 'src/app/core/store/loyalty/selector/loyalty.selector';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';

@Component({
  selector: 'app-cashback-modal',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './cashback-modal.component.html',
  styleUrl: './cashback-modal.component.scss',
})
export class CashbackModalComponent implements OnDestroy {
  @Input() set modalData(data: any) {
    this.openModal = data?.openModal;
    this.percentage = data?.cashBack;
    this.amount = data?.amount;
    this.img = this.getImg(data?.openModal, data?.cashBack) || '';
  }

  openModal: string = '';
  unsubus$: Subject<boolean> = new Subject();
  loyalty$: Observable<any> = this.store.select(selectLoyalty);
  img: string = '';
  percentage: string = '';
  amount: number = 0;
  constructor(
    private navigationService: NavigationService,
    private store: Store
  ) {}

  getImg(type: string, cashback: string): string | undefined {
    if (type === 'levelUp') {
      const levels = [
        {
          levelName: 'Sailor',
          cashback: '8%',
          image: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/sailor_4.svg',
        },
        {
          levelName: 'Boatswain',
          cashback: '9%',
          image: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/bocman_4.svg',
        },
        {
          levelName: 'Captain',
          cashback: '10%',
          image: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/kapitan_4.svg',
        },
        {
          levelName: 'Admiral',
          cashback: '11%',
          image: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/admiral_4.svg',
        },
        {
          levelName: 'Triton',
          cashback: '13%',
          image: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/triton.svg',
        },
        {
          levelName: 'Poseidon',
          cashback: '15%',
          image: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/poseidon.svg',
        },
      ];

      const foundLevel = levels.find((level) => level.cashback === cashback);
      return foundLevel ? foundLevel.image : '';
    } else {
      const cashbackTiers = [
        {
          min: 2500000,
          img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/cashback_Poseidon.png',
        },
        {
          min: 500000,
          img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/cashback_Triton.png',
        },
        {
          min: 200000,
          img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/cashback_Admiral.png',
        },
        {
          min: 50000,
          img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/cashback_Captain.png',
        },
        {
          min: 5000,
          img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/cashback_Boatswain.png',
        },
        { min: 0, img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/cashback_Sailor.png' },
      ];

      const tier = cashbackTiers.find((t) => this.amount >= t.min) || cashbackTiers[cashbackTiers.length - 1];
      return tier ? tier.img : '';
    }
  }

  close() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }

  navigateTo(url: string) {
    this.navigationService.navigateTo(url);
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
