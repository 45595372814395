import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BrowserReloadsActions } from '../../browser/actions/browser-reloads.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigActions } from '../actions/config.actions';
import { HelpersService } from 'src/app/core/services/helpers/helpers.service';
import { CookieService } from 'src/app/core/services/cookies/cookie.service';

@Injectable()
export class ConfigEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private helper: HelpersService,
    private cookieService: CookieService
  ) {}

  $config = createEffect(() =>
    this.actions$.pipe(
      ofType(BrowserReloadsActions['[BrowserActions]BrowserReloadss']),
      mergeMap(() =>
        this.http.get<any>('/bootstrap').pipe(
          map((res: any) => {
            return ConfigActions['[config]ConfigsSuccess']({
              ...res,
              // user: {
              //   ...res.user,
              //   FakeEmail: res?.user?.Email,
              //   Email: this.cookieService.getCookieValue('alterEmail') || res?.user?.Email,
              //   email: this.cookieService.getCookieValue('alterEmail') || res?.user?.email,
              // },
              languages:
                this.helper.getCookie('_aff')?.includes('174') || res?.user?.AffiliateID === '70012640'
                  ? res?.languages.filter((elem: any) => elem.code !== 'sv')
                  : res?.languages,
            });
          }),
          catchError((err: any) => of(ConfigActions['[config]ConfigsFailure'](err)))
        )
      )
    )
  );
}
