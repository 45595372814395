import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { InformationComponent } from 'src/app/pages/information/information.component';
import { MainButtonComponent } from '../../buttons/main-button/main-button.component';
import { KeystoneService } from 'src/app/core/services/keystone/keystone.service';
import { Store } from '@ngrx/store';
import { TermsAndConditionsService } from 'src/app/core/services/terms-and-conditions/terms-and-conditions.service';
import { CookieService } from 'src/app/core/services/cookies/cookie.service';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { Observable, Subject } from 'rxjs';
import { selectLoyalty } from 'src/app/core/store/loyalty/selector/loyalty.selector';

@Component({
  selector: 'app-landing-modal',
  standalone: true,
  imports: [CommonModule, InformationComponent, MainButtonComponent, NgIf],
  templateUrl: './landing-modal.component.html',
  styleUrl: './landing-modal.component.scss',
})
export class LandingModalComponent implements OnInit, OnDestroy {
  @Input()
  preselectedSlug: string = '';
  @Input()
  termsRenewConfirmation: boolean = false;
  isTermsModal: boolean = true;
  @Input() isLoyalty: boolean = false;
  unsubus$: Subject<boolean> = new Subject();
  loyalty$: Observable<any> = this.store.select(selectLoyalty);

  constructor(
    private keystone: KeystoneService,
    private store: Store,
    private termsAndCondService: TermsAndConditionsService,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    console.log(this.termsRenewConfirmation);
  }

  acceptTermsInTermsAndCondRenew() {
    this.keystone.getAllTermsPages().subscribe((res: any) => {
      if (res[0].version) {
        this.termsAndCondService.postStorage('terms', res[0].version).subscribe();
        this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
      }
    });
    // this.questCheck();
  }

  // questCheck() {
  //   setTimeout(() => {
  //     this.loyalty$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
  //       if (
  //         res?.isLoaded &&
  //         res?.readyToClaimQuest?.readyToClaimAuthQuest &&
  //         !this.cookie.getCookie('readyToClaimAuthQuest')
  //       ) {
  //         const today = new Date();
  //         today.setUTCHours(23, 59, 0, 0);
  //         document.cookie = `readyToClaimAuthQuest=${true};expires=${today.toUTCString()};path=/;`;
  //         this.store.dispatch(
  //           ModalsActions['[modals]OpenModalSuccess']({
  //             id: 'quest-notification',
  //             state: 'notification',
  //           })
  //         );
  //       }
  //     });
  //   }, 1500);
  // }

  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
