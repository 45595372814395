export const imagesUrl: any = {
  popa: 'https://cdn.playasballs.com//wazbee/icons/Sidebar/Payout/avarage_cash-out_time.svg',
  logoWide: 'https://cdn.chipychapa.com//welle/Logo_n_fav/welle_logo.svg',
  logoShort: 'https://cdn.chipychapa.com//welle/Logo_n_fav/mini_logo.svg',
  genderMale: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/male.svg',
  genderFemale: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/female.svg',
  genderUnfilled: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/anonim.svg',
  userBalance: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/user_balance.svg',
  userBonusBalance: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/user_bonus_balance.svg',
  welleCoin: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/welle_coin_balance.svg',
  bell: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/notification_bell.svg',
  cashier: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/cashier.svg',
  bonuses: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Bonuses/bonuses.svg',
  loyalty: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/Loyalty_icon.svg',
  promotions: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/PROMOTIONS/Promotions_icon.svg',
  tournaments: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/TOURNAMENTS/Tournaments_icon.svg',
  vipClub: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/VIP_CLUB/vipclub_icon.svg',
  account: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Account/account_icon_new.svg',
  support: 'https://cdn.playasballs.com//wazbee/icons/Sidebar/support.svg',
  showMore: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/open_accordion.svg',
  showLess: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/close_accordion.svg',
  signOut: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/sign_out.svg',
  mobileMainPchel: 'https://cdn.playasballs.com//wazbee/icons/hero/vpnbee2.png',
  mainBg: 'https://cdn.playasballs.com//wazbee/icons/back.svg',
  fishConfident: 'https://cdn.chipychapa.com//welle/General_site/Notifications/fish.svg',
  supportCorner: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Help/support.svg',
  hotGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/Hot_icon.svg',
  hotGamesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/hot_games.svg',
  // ChristmasGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/christmas_icon.svg',
  // ChristmasGamesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/christmas_icon.svg',
  newGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/new_icon.svg',
  newGamesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/new.svg',
  popularCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/sea_fav_icon.svg',
  popularCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/popular.svg',
  featureGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/feature_buy_icon.svg',
  featureGamesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/feature_buy.svg',
  jackpotGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/wellaschoice_icon.svg',
  jackpotGamesCategoriesIsHover:
    'https://cdn.chipychapa.com//welle/General_site/Categories/categories/welles_choice.svg',
  // catchTheWaveCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/catch_the_wave_icon.svg',
  // catchTheWaveCategoriesIsHover:
  //   'https://cdn.chipychapa.com//welle/General_site/Categories/categories/catch_the_wave.svg',
  liveGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/livecasino_icon.svg',
  liveGamesCategoriesIsHover: '',
  // scratchCardCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/scratch_icon.svg',
  // scratchCardCategoriesIsHover:
  //   'https://cdn.chipychapa.com//welle/General_site/Categories/categories/scratch_cards.svg',
  megawavesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/megawaves_icon.svg',
  megawavesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/megawaves.svg',
  spingiftsCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Tournaments/Icons/Spin_Gifts.svg',
  spingiftsCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Tournaments/Category/Spingifts.svg',
  // tableGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/table_icon.svg',
  // tableGamesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/table_games.svg',
  dropAndWinsGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/drop_and_wins_icon.svg',
  dropAndWinsGamesCategoriesIsHover: '',
  // otherGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/others_icon.svg',
  // otherGamesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/other.svg',
  favoritesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/favorites_icon.svg',
  favoritesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/favorites.svg',
  lastGamesCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/last_played_icon.svg',
  lastGamesCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/last_played.svg',
  providersCategoriesNoHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/providers_icon.svg',
  providersCategoriesIsHover: 'https://cdn.chipychapa.com//welle/General_site/Categories/providers.svg',
  noSearchResult: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/no_available_bonuses.png',
  noTournaments:
    'https://cdn.chipychapa.com//welle/General_site/Sidebar/Bonuses/Tournaments/tournaments_are_coming.svg',
  leftArrow: 'https://cdn.playasballs.com//wazbee/icons/BackToTopArrow.svg',
  help: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Help/help.svg',
  features: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/features.svg',
  loyaltyBonus_0: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/daily_bonus.png',
  loyaltyBonus_1: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/weekly_bonus.png',
  loyaltyBonus_2: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/monthly_bonus.png',
  loyaltyBonus_3: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/boost.png',
  claimed: 'https://cdn.chipychapa.com/welle/General_site/Sidebar/Features/Loyalty/calendar/taken.svg',
  readyToTake:
    'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/calendar/animation_calendar_box_to_take.svg',
  active: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/calendar/comming_reward1.svg',
  lost: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/calendar/lost.svg',
  //loyalty ranks
  admiral_1: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/admiral_1.svg',
  admiral_2: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/admiral_2.svg',
  admiral_3: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/admiral_3.svg',
  admiral_4: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/admiral_4.svg',
  admiral_5: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/admiral_5.svg',
  boatswain_1: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/bocman_1.svg',
  boatswain_2: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/bocman_2.svg',
  boatswain_3: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/bocman_3.svg',
  boatswain_4: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/bocman_4.svg',
  boatswain_5: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/bocman_5.svg',
  captain_1: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/kapitan_1.svg',
  captain_2: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/kapitan_2.svg',
  captain_3: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/kapitan_3.svg',
  captain_4: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/kapitan_4.svg',
  captain_5: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/kapitan_5.svg',
  legend_of_the_seas_1: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/legenda_1.svg',
  legend_of_the_seas_2: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/legenda_2.svg',
  legend_of_the_seas_3: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/legenda_3.svg',
  legend_of_the_seas_4: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/legenda_4.svg',
  legend_of_the_seas_5: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/legenda_5.svg',
  poseidon: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/poseidon.svg',
  cabin_boy_1: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/sailor_1.svg',
  cabin_boy_2: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/sailor_2.svg',
  cabin_boy_3: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/sailor_3.svg',
  cabin_boy_4: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/sailor_4.svg',
  cabin_boy_5: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/sailor_5.svg',
  triton: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/ranks/triton.svg',
};
