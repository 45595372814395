import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { appState } from 'src/app';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';

@Component({
  selector: 'app-deposit-characteristics-methods-modal',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './deposit-characteristics-methods-modal.component.html',
  styleUrl: './deposit-characteristics-methods-modal.component.scss',
})
export class DepositCharacteristicsMethodsModalComponent {
  @Input() type: string = '';
  constructor(
    private store: Store<appState>,
    private navigatro: NavigationService
  ) {}

  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  goToTerms() {
    this.navigatro.navigateTo('/info/terms');
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
