import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectUser } from 'src/app/core/store/user/selectors/user.selector';
import { TranslocoModule } from '@ngneat/transloco';
import { UserCurrencyPipePipe } from 'src/app/core/pipes/userCurrencyPipe/user-currency-pipe.pipe';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';

@Component({
  selector: 'app-active-bonus-cancel-modal',
  standalone: true,
  imports: [TranslocoModule, UserCurrencyPipePipe],
  templateUrl: './active-bonus-cancel-modal.component.html',
  styleUrl: './active-bonus-cancel-modal.component.scss',
})
export class ActiveBonusCancelModalComponent implements OnInit, OnDestroy {
  user$: Observable<any> = this.store.select(selectUser);
  unsubus$: Subject<boolean> = new Subject();

  bonusBalance: number = 0;
  userCurrency: string = '';
  constructor(
    private store: Store,
    private modalDataTransferService: ModalsDataTransferService
  ) {}
  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (Object.keys(res?.userInfo?.data?.loyalty?.BonusesBalance).length > 0) {
        const activeBonusBalanceArr: Array<any> = Object.values(res.userInfo.data.loyalty.BonusesBalance);
        this.bonusBalance = Number(activeBonusBalanceArr[0].Balance);
        this.userCurrency = res?.userInfo?.data?.currency;
      }
    });
  }
  closeModal() {
    this.modalDataTransferService.cancleActiveBonus(false);
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  cancleBonus() {
    this.modalDataTransferService.cancleActiveBonus(true);
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
