import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from '.';
import { BrowserReloadsActions } from './core/store/browser/actions/browser-reloads.actions';
import { selectConfig } from './core/store/config/selector/config.selectors';
import { Observable, Subject, catchError, delay, of, switchMap, takeUntil, tap, timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HelpersActions } from './core/store/helpers/actions/helpers.actions';
import { HelpersService } from './core/services/helpers/helpers.service';
import { LanguageService } from './core/services/language/language.service';
import { GetUser } from './core/store/user/actions/user.actions';
import { selectUser } from './core/store/user/selectors/user.selector';
import { GetBonuses } from './core/store/bonuses/actions/bonus.actions';
import { GetUserInfo } from './core/store/user/actions/userInfo.action';
import { LocalStorageService } from './core/services/localStorage/local-storage.service';
import { CookieService } from './core/services/cookies/cookie.service';
import { LoyaltyService } from './core/services/loyalty/loyalty.service';
import { MetadataService } from './core/services/metabase/metadata.service';
// import { LoyaltyActions } from './core/store/loyalty/actions/loyalty.actions';
import { BonusesService } from './core/services/bonuses/bonuses.service';
import { GamesService } from './core/services/gameCatalog/games.service';
import { languages } from './core/interfaces/interfaces';
import { FinancesService } from './core/services/finances/finances.service';
import * as Sentry from '@sentry/angular';
import { ModalsActions } from './core/store/modals/actions/modals.actions';
import { LoyaltyActions } from './core/store/loyalty/actions/loyalty.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChildren('checkboxes')
  checkboxes!: QueryList<ElementRef>;

  delay: ReturnType<typeof setTimeout> = setTimeout(() => {});
  width: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth === this.width) {
      return;
    }
    this.width = window.innerWidth;
    clearTimeout(this.delay);
    this.delay = setTimeout(() => {
      this.isMobile = window.innerWidth < 786 ? true : false;
      this.store.dispatch(
        HelpersActions['[helpers]SetIsMobileStatus']({
          data: this.isMobile,
        })
      );
      this.store.dispatch(
        HelpersActions['[helpers]SetScreenWidth']({
          data: window.innerWidth,
        })
      );
    }, 100);
  }

  isOpen: boolean = false;

  title: string = 'test';
  currentLang: string = 'en';

  config$: Observable<any> = this.store.select(selectConfig);
  user$: Observable<any> = this.store.select(selectUser);

  unsubus$: Subject<boolean> = new Subject();
  isMobile: boolean = true;
  isAuth: boolean = false;

  preloaderAnimate: boolean = false;
  preloader: boolean = true;
  onceLoyalty: boolean = true;
  onceLoyaltyRegister: boolean = true;
  once: boolean = true;
  onceSocket: boolean = true;
  userCurrency: string = '';
  onceSentry: boolean = true;
  constructor(
    private store: Store<appState>,
    public activatedRoute: ActivatedRoute,
    private helpers: HelpersService,
    private language: LanguageService,
    private storage: LocalStorageService,
    private cookie: CookieService,
    private loyaltyService: LoyaltyService,
    private metadateService: MetadataService,
    private games: GamesService,
    private bonusService: BonusesService,
    private finance: FinancesService
  ) {
    this.onResize();
    if (document.readyState == 'complete') {
      this.preloadHide();
    }
    window.onload = () => {
      this.preloadHide();
    };
    const browserLang: Array<string> = this.helpers.getBrowserlanguages();
    this.language.setInitialPreferedLanguage(this.helpers.getCookie('_aff')?.includes('174') ? ['en'] : browserLang);
    this.store.dispatch(
      HelpersActions['[helpers]SetBrowserName']({
        data: this.helpers.detectBrowser(),
      })
    );
    this.store.dispatch(
      HelpersActions['[helpers]GetBrowserPreferedLanguages']({
        data: browserLang,
      })
    );
    this.store.dispatch(BrowserReloadsActions['[BrowserActions]BrowserReloadss']());
  }

  themeSwitcher() {
    throw new Error('Sentry Test Error');
    // if (localStorage.theme === 'dark' ? true : false) {
    //   localStorage.theme = 'light';
    //   document.documentElement.classList.remove('dark');
    // } else {
    //   localStorage.theme = 'dark';
    //   document.documentElement.classList.add('dark');
    // }
  }

  preloadHide() {
    const preloader = document.getElementById('preloader');
    timer(500)
      .pipe(
        tap(() => {
          const testHide = document.getElementById('testHide');
          testHide?.classList.add('preloader-hide');
          setTimeout(() => {
            testHide?.remove();
          }, 2000);
          preloader?.classList.add('preloader-hide');
        }),
        delay(500)
      )
      .subscribe(() => preloader?.remove());
  }

  ngOnInit() {
    this.helpers.disablePseudoElementsIPhone();
    this.helpers.blockDoubleTapIPhone();
    this.games.initFilterFromStorageToStore();

    const bonusLoop = timer(0, 15000);

    this.config$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res?.isLoaded) {
        this.storage.setSessionStorage('country', res?.config?.country);
        if (res?.config?.user) {
          if (this.onceSentry) {
            this.onceSentry = false;
            Sentry.setUser({ email: res.config.user.Email });
          }

          if (this.storage.getSessionStorage('loyalty_access_token')) {
            this.store.dispatch(HelpersActions['[helpers]SetAccesToken']({ data: true }));
            this.store.dispatch(LoyaltyActions['[Loyalty]GetUsetTurnover']());

            // this.store.dispatch(LoyaltyActions['[Loyalty]GetCombinedLoyaltyData']());
            // this.store.dispatch(LoyaltyActions['[Loyalty]GetWellBetAndReefback']());
            // this.store.dispatch(LoyaltyActions['[Loyalty]GetFinishedQuestsCount']());
            this.connectLoyaltySocket(res.config.user.UserId, res.config.user.loyalty.Login);

            this.finance
              .getReefBack(res?.config?.user?.currency, 'EUR', 1)
              .pipe(takeUntil(this.unsubus$))
              .subscribe((res: any) => {
                if (res) {
                  this.storage.setSessionStorage('exchangeRate', res?.rate);
                }
              });
          }
          this.store.dispatch(GetUser['[GetUser]GetUser']());
          const lang =
            this.helpers.getCookie('_aff')?.includes('174') || res?.config?.user?.AffiliateID === '70012640'
              ? 'en'
              : this.helpers.getCookie('userlang') || res?.config.user?.loyalty?.Language;
          this.language.setLanguage(lang);
          this.cookie.setCookie('userlang', lang, '/');
          this.games
            .getGamesFromFavoriteList()
            .pipe(takeUntil(this.unsubus$))
            .subscribe((response: any) => {
              const favoriteGameIds = response.map((game: { game_id: string }) => game.game_id);
              this.storage.setLocalStorage('favGames', JSON.stringify(favoriteGameIds));
            });

          if (this.onceLoyalty && !this.storage.getSessionStorage('loyalty_access_token') && res.config.user) {
            const userId: string = res.config.user.UserId;
            const email: string = res.config.user.Email;
            const passws: string = userId + email;
            this.userCurrency = res.config.user?.currency;
            this.connectLoyaltySocket(res.config.user.UserId, res.config.user.loyalty.Login);
            this.onceLoyalty = false; // this.onceLoyalty = false; REMOVE! after backend update
            this.loginUserLoyalty(userId, passws, email); //todo is firtsession
          }
        } else {
          const userlangCookie = this.helpers.getCookie('userlang');
          if (userlangCookie) {
            this.language.setLanguage(userlangCookie);
          }
        }
        if (!this.storage.getSessionStorage('sortCurrencies')) {
          this.finance.setSortedCurrencies(res?.config?.country);
        }
        this.storage.setSessionStorage('country', res?.config?.country);
        this.store.dispatch(
          HelpersActions['[helpers]SetPossibleLanguages']({
            data: res?.config?.status === 451 ? languages : res?.config?.languages,
          })
        );

        this.store.dispatch(
          HelpersActions['[helpers]SetCurrentCountry']({
            data: res?.config?.country,
          })
        );
      }
    });
    this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res?.loaded) {
        const countryCode = res?.userProfile?.data?.countryCode;
        if (res.isAuth) {
          const activeBonus =
            res?.userInfo?.data?.loyalty?.ActiveBonuses &&
            Object.keys(res?.userInfo?.data?.loyalty?.ActiveBonuses).length > 0
              ? JSON.stringify(res?.userInfo?.data?.loyalty?.ActiveBonuses)
              : '';
          this.loyaltyService.sendSocketActiveBonus(!!activeBonus, res?.userInfo?.data?.idUser);
        }
        countryCode
          ? this.storage.setSessionStorage('countryFromProfile', countryCode)
          : this.storage.removeSessionStorage('countryFromProfile');

        this.isAuth = res.isAuth;
        if (this.once && this.isAuth) {
          this.store.dispatch(GetBonuses['[GetBonuses]GetBonuses']());
          this.metadateService.addTags(
            res?.userProfile?.data,
            res?.userInfo?.data.Tags,
            'welleTags',
            this.helpers.getBirthdayDate(
              res?.userProfile?.data?.birthDay,
              res?.userProfile?.data?.birthMonth,
              res?.userProfile?.data?.birthYear
            ),
            res?.userInfo?.data?.idUser
          );
          this.metadateService.syncTags(res?.userProfile?.data);
          this.once = false;
        }
      }
    });
    bonusLoop.subscribe(() => {
      if (this.isAuth) {
        this.store.dispatch(GetUserInfo['[GetUserInfo]GetUserInfo']());
        this.store.dispatch(GetBonuses['[GetBonuses]GetBonuses']());
      }
    });
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params?.message && params?.message === 'PAYMENT_FAIL') {
        this.store.dispatch(
          ModalsActions['[modals]OpenModalSuccess']({
            id: 'payments',
            unClosable: true,
            state: params?.message,
          })
        );
      }
    });
  }

  registerUserLoyalty(userId: string, passws: string, email: string) {
    this.loyaltyService
      .loyaltyRegistration(userId, passws, email, email)
      .pipe(
        takeUntil(this.unsubus$),
        switchMap(() => {
          return this.loyaltyService.getLoyaltyAccesToken();
        }),
        catchError((error: any) => {
          console.log(error);
          if (error.status === 418 && this.onceLoyaltyRegister) {
            this.loginUserLoyalty(userId, passws, email);
            this.onceLoyaltyRegister = false;
          }
          return of(null);
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.loyaltyService.setLoyaltyCookies(res);
          this.store.dispatch(HelpersActions['[helpers]SetAccesToken']({ data: true }));
          this.store.dispatch(LoyaltyActions['[Loyalty]GetUsetTurnover']());
        }
      });
  }

  loginUserLoyalty(userId: string, passws: string, email: string) {
    this.loyaltyService
      .loyaltyLogin(userId, passws, email, email)
      .pipe(
        takeUntil(this.unsubus$),
        switchMap(() => {
          return this.loyaltyService.getLoyaltyAccesToken();
        }),
        catchError((error: any) => {
          console.log(error);
          if (error.status === 400 && this.onceLoyaltyRegister) {
            this.registerUserLoyalty(userId, passws, email);
            this.onceLoyaltyRegister = false;
          }
          return of(null);
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.loyaltyService.setLoyaltyCookies(res);
          setTimeout(() => {
            this.store.dispatch(HelpersActions['[helpers]SetAccesToken']({ data: true }));
            this.store.dispatch(LoyaltyActions['[Loyalty]GetUsetTurnover']());

            // this.store.dispatch(LoyaltyActions['[Loyalty]GetFinishedQuestsCount']());
            // this.finance
            //   .getReefBack(this.userCurrency, 'EUR', 1)
            //   .pipe(takeUntil(this.unsubus$))
            //   .subscribe((res: any) => {
            //     if (res) {
            //       this.storage.setSessionStorage('exchangeRate', res?.rate);
            //     }
            //   });
          }, 100);
        }
      });
  }
  connectLoyaltySocket(userId: string, login: string) {
    this.loyaltyService.connect('https://welle-api.coduluz.com', userId, login).subscribe({
      next: (res) => this.loyaltyService.addDataFromSocket(res),
      error: (err) => console.error('WebSocket error:', err),
    });
  }

  ngOnDestroy() {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
