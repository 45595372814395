<div class="m-4 mt-0 relative">
  <app-information
    [isLoyalty]="isLoyalty"
    [preselectedSlug]="preselectedSlug"
    [termsRenewConfirmation]="termsRenewConfirmation"
    [isLanding]="true"
    [isTermsModal]="isTermsModal"></app-information>
  <div
    *ngIf="termsRenewConfirmation"
    class="fixed inset-x-0 mx-auto max-w-[65%] mM:max-w-[70%] sm:max-w-[31.25rem] lg:max-w-[25rem] bottom-[3.75rem] md:bottom-[8vh] l7xl:bottom-[9vh] w-full">
    <app-main-button (click)="acceptTermsInTermsAndCondRenew()" name="Accept" variant="yellowBg"></app-main-button>
  </div>
</div>
