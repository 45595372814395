<div class="w-full box-shadow p-2 py-3" *transloco="let t">
  <div class="w-full">
    <div class="p-2 w-full rounded-lg grid md:grid-cols-3 grid-cols-[20%,1fr,20%]">
      <h1 class="font-semibold text-sm mmd:text-lg text-center col-start-2">
        {{ t('sidenav.cashier.withdraw.limits_title') }}
      </h1>
      <div class="cursor-pointer h-6 w-6 flex justify-self-end items-center" (click)="closeModal()">
        <img
          width="16"
          height="16"
          src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/close.svg"
          alt="close modal" />
      </div>
    </div>
  </div>

  <!-- laptop, desktop and tablet view -->
  <div
    class="flex w-full flex-col gap-2 lg:items-center lg:text-center py-2 mmd:px-2 text-mainTextLight dark:text-mainText">
    <div class="flex flex-col gap-2 text-center">
      <div class="font-normal text-base flex flex-col">
        <span class="font-bold">{{ withdrawLimits?.byCards }}</span>

        <div class="flex flex-col">
          @for (item of withdrawLimits?.cardLimit; track $index) {
            <span>{{ item }}</span>
          }
        </div>
      </div>
      <div class="font-normal text-base flex flex-col">
        <span class="font-bold">{{ withdrawLimits?.byWallets }}</span>

        <div class="flex flex-col">
          @for (item of withdrawLimits?.walletLimit; track $index) {
            <span>{{ item }}</span>
          }
        </div>
      </div>
      <div class="font-normal text-base flex flex-col">
        <span class="font-bold">{{ withdrawLimits?.byCrypto }}</span>

        <div class="flex flex-col">
          @for (item of withdrawLimits?.cryptoLimit; track $index) {
            <span>{{ item }}</span>
          }
        </div>
      </div>
    </div>
    <div>
      <p class="font-normal text-base">
        {{ withdrawLimits?.depByCard }}
        <span class="font-bold">{{ withdrawLimits?.depByCardLimit }}</span>
      </p>
      <p class="font-normal text-base">
        {{ withdrawLimits?.depByCrypto }}
        <span class="font-bold">{{ withdrawLimits?.depByCryptoLimit }}</span>
      </p>
    </div>
    <!-- img to withdrawal limits example -->
    <div class="flex flex-col">
      <h6 class="font-normal text-base">{{ withdrawLimits?.example }}</h6>
      <div class="pb-1 md:pb-3 flex justify-center">
        <img [src]="withdrawLimits?.limitImage" alt="example_limits" class="relative" />
        <a [href]="withdrawLimits?.limitImage" target="_blank">
          <img
            src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/zoom.svg"
            alt="zoomIn"
            class="absolute bottom-5 right-4 sm:hidden" />
        </a>
      </div>
    </div>
  </div>
</div>
