<ng-container *transloco="let t">
  <div
    [class]="
      'bg-[#FEFCE8] relative flex flex-col justify-between items-center text-mainTextLight pb-6   ' +
      (openModal === 'levelUp' ? 'min-h-[30.81rem] ' : 'min-h-[28.31rem] ')
    ">
    <img
      width="407"
      height="200"
      class="absolute top-0 left-1/2 transform -translate-x-1/2 z-0"
      src="https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/confetti.svg" />

    <button (click)="close()" class="absolute top-2 right-2 w-6 h-6 z-[1]">
      <img
        src="https://cdn.chipychapa.com//welle/General_site/Autharization/close_page.svg"
        width="16"
        height="16"
        alt="cross"
        id="close" />
    </button>
    <!-- <div class="absolute top-0 w-full h-[12.5rem] confetti-wrapper"></div> -->
    <div class="pb-16 pt-16 z-[1]">
      <img
        [class]="'  ' + (openModal === 'levelUp' ? 'w-[6.38rem] h-[6.38rem]' : 'w-[5.63rem] h-[5.63rem]')"
        [src]="img"
        alt="icon" />
    </div>
    <div class="flex flex-col justify-end items-center gap-4 z-[1]">
      <h2 class="text-[1.63rem] font-bold">
        {{ t(openModal === 'levelUp' ? 'loyalty.modal.cashback_level_up' : 'loyalty.modal.available_cashback') }}
      </h2>
      <div class="pb-4 text-sm font-semibold max-w-[17rem] text-center">
        @if (openModal === 'levelUp') {
          <p>
            {{ t('loyalty.modal.cashback_level_up.text.first_part') }}
            <strong class="font-black">{{ percentage }}</strong>
            {{ t('loyalty.modal.cashback_level_up.text.second_part') }}
            <strong class="font-black">{{ t('loyalty.title.bonus_page') }}!</strong>
          </p>
        } @else {
          <p>
            {{ t('loyalty.modal.available_cashback.text.first_part') }}
            <strong class="font-black">{{ t('loyalty.title.bonus_page') }}!</strong>
            {{ t('loyalty.modal.available_cashback.text.in_the') }}
            <strong class="font-black">{{ t('loyalty.shop.available') }}</strong>
            {{ t('loyalty.modal.available_cashback.text.section') }}
          </p>
        }
      </div>

      <button
        (click)="navigateTo('/(aside:rewards/bonuses)')"
        class="h-14 w-[19.19rem] bg-mainBtnLight hover:bg-mainHoverLight rounded-2xl">
        {{ t('buttons.go_to_bonus') }}
      </button>
      @if (openModal === 'levelUp') {
        <button
          (click)="navigateTo('/loyalty')"
          class="h-14 w-[19.19rem] bg-skyBtnHoverLight hover:bg-btnSkyBgLight rounded-2xl">
          {{ t('buttons.go_to_loyalty') }}
        </button>
      }
    </div>
  </div>
</ng-container>
