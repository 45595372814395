<div class="flex flex-col gap-4 py-6 px-4 justify-center items-center text-mainTextLight" *transloco="let t">
  <img
    src="https://cdn.chipychapa.com//welle/General_site/Notifications/warning.svg"
    alt="warning"
    width="34"
    height="34" />
  <h2 class="font-semibold text-lg">{{ t('sidenav.title.bonus_cancle') }}</h2>
  <p class="text-base font-normal md:max-w-[18.75rem] text-center">{{ t('loyalty.text.cancle_modal') }}</p>
  <div
    class="w-full md:max-w-[15.75rem] h-[5.44rem] flex flex-col gap-2 bg-inputBackground rounded-[0.63rem] border-[0.06rem] border-[#0EA5E9] text-center justify-center items-center px-[0.63rem] py-1">
    <span class="font-medium text-base">Your current bonus balance:</span>
    <span class="font-bold text-[1.75rem]">{{ userCurrency | userCurrencyPipe }}{{ bonusBalance.toFixed(2) }}</span>
  </div>
  <button
    (click)="closeModal()"
    class="font-semibold text-base rounded-xl bg-mainBtnLight hover:bg-mainHoverLight w-full h-14">
    {{ t('buttons.leave_the_bonus') }}
  </button>
  <button (click)="cancleBonus()" class="font-semibold text-base w-full h-14">{{ t('buttons.cancle_bonus') }}</button>
</div>
